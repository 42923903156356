import React from 'react'
import GetAllBetaUsersLayout from '../../containers/beta/get_all_beta_user'

function AllBetaUsers() {
    return (
        <div>
            <GetAllBetaUsersLayout />
            
        </div>
    )
}

export default AllBetaUsers

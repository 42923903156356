import {
  AUTO_RACE_POOL_CLEAN,
  CREATE_AUTO_RACE_POOL_ERROR,
  CREATE_AUTO_RACE_POOL_SUCCESS,
  DELETE_AUTO_RACE_POOL_ERROR,
  DELETE_AUTO_RACE_POOL_SUCCESS,
  GET_ALL_AUTO_RACE_POOL_SUCCESS,
  UPDATE_AUTO_RACE_POOL_ERROR,
  UPDATE_AUTO_RACE_POOL_SUCCESS,
} from "../constants/autoRacePool";

export default function autoRacePoolReducer(
  state = {},
  { payload, type, error, success }
) {
  switch (type) {
    case CREATE_AUTO_RACE_POOL_SUCCESS:
      return { ...state, isSuccess: success, isError: error };
    case CREATE_AUTO_RACE_POOL_ERROR:
    case UPDATE_AUTO_RACE_POOL_ERROR:
    case DELETE_AUTO_RACE_POOL_ERROR:
      return {
        ...state,
        alertMsg: payload,
        isError: error,
        isSuccess: success,
      };
    case UPDATE_AUTO_RACE_POOL_SUCCESS:
      return { ...state, isUpdated: payload, isError: error };
    case DELETE_AUTO_RACE_POOL_SUCCESS:
        return { ...state, isDeleted: payload, isError: error };
    case GET_ALL_AUTO_RACE_POOL_SUCCESS:
      return {
        ...state,
        autoPoolRaces: payload,
        isError: error,
        isSuccess: success,
      };
    case AUTO_RACE_POOL_CLEAN:
      return {
        isUpdated: false,
        isDeleted: false,
        isError: false,
        isSuccess: false
      }
    default:
      return state;
  }
}

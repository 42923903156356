import { backend } from "../../axios";
import {
  ADD_SETTING_FAIL,
  ADD_SETTING_REQUEST,
  ADD_SETTING_RESET,
  ADD_SETTING_SUCCESS,
} from "../../constants/settingConstants";

export const addSettingAction = (name, value, message) => async (dispatch) => {
  try {
    dispatch({ type: ADD_SETTING_REQUEST });

    const response = await backend.post('/api/settings', {
      name,
      value,
      message,
    });

    dispatch({
      type: ADD_SETTING_SUCCESS,
      payload: response,
    });

    setTimeout(() => {
      dispatch({
        type: ADD_SETTING_RESET,
      });
    }, 2000);
  } catch (error) {
    dispatch({
      type: ADD_SETTING_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

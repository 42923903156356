import React, { useEffect } from "react";
import { Form, Input, InputNumber, Button, Select } from "antd";
import { useDispatch } from "react-redux";
import { nftContractTypes } from "../../constants";
import { replaceBlackList } from "../../actions/blackLists";

const { Option } = Select;
const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 14,
  },
  layout: "horizontal",
  size: 100,
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const CreateBlackList = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      contractType: nftContractTypes[0].value,
      tokenId: null,
      stealerUserWalletId: null,
      reporterUserWalletId: null,
      reporterUserName: null,
    });
  }, [form]);

  const onFinish = (values) => {
    dispatch(
      replaceBlackList({
        ...values,
        isBlackListed: true,
      })
    );
  };

  return (
    <div className="right-section" autoComplete="off">
      <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item
          label="NFT Type"
          name="contractType"
          rules={[{ required: true }]}
        >
          <Select>
            {nftContractTypes.map((type) => (
              <Option value={type.value}>{type.text}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Stealer User Wallet ID"
          name="stealerUserWalletId"
          style={{ marginTop: 50 }}
          rules={[{ required: true }]}
        >
          <Input placeholder="Stealer User Wallet ID" />
        </Form.Item>

        <Form.Item
          label="Token ID"
          name="tokenId"
          rules={[{ required: true }]}
        >
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Enter Token ID"
          />
        </Form.Item>

        <Form.Item
          label="Reporter User Name"
          name="reporterUserName"
          rules={[{ required: true }]}
        >
          <Input placeholder="Reporter User Name" />
        </Form.Item>

        <Form.Item
          label="Reporter User Wallet ID"
          name="reporterUserWalletId"
          rules={[{ required: true }]}
        >
          <Input placeholder="Reporter User Wallet ID" />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateBlackList;

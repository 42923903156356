import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Input, Popconfirm, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

import { nftContractTypes } from "../../constants";
import {
  cleanBlackLists,
  replaceBlackList,
  getAllBlackLists,
} from "../../actions/blackLists";

const List = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [sort, setSort] = useState();
  let searchInput = useRef();

  const dispatch = useDispatch();
  const blackLists = useSelector(
    (state) => state.blackList.blackLists
  );
  const isDeleted = useSelector((state) => state.blackList.isSuccess);

  const onDelete = (blackList) => {
    const {
      stealerUserWalletId,
      contractType,
      tokenId,
      reporterUserName,
      reporterUserWalletId,
    } = blackList;

    dispatch(replaceBlackList({
      stealerUserWalletId,
      contractType,
      tokenId,
      reporterUserName,
      reporterUserWalletId,
      isBlackListed: false,
    }));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilter({});
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sort = sorter.columnKey
      ? {
          field: sorter.columnKey,
          order: sorter.order === "ascend" ? "ASC" : "DESC",
        }
      : undefined;

    setPage(pagination.current);
    setLimit(pagination.pageSize);
    setFilter(filters);
    setSort(sort);
  };

  const getBlackLists = () => {
    dispatch(
      getAllBlackLists({
        page,
        limit,
        filter,
        sort,
      })
    );
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "Stealer User Wallet ID",
      dataIndex: "stealerUserWalletId",
      key: "stealerUserWalletId",
      ...getColumnSearchProps("stealerUserWalletId"),
    },
    {
      title: "NFT Type",
      dataIndex: "contractType",
      key: "contractType",
      filters: nftContractTypes,
      render: (value, record) => {
        const type = nftContractTypes.find((t) => t.value === value);
        return type.text;
      },
    },
    {
      title: "Token ID",
      dataIndex: "tokenId",
      key: "tokenId",
      ...getColumnSearchProps("tokenId"),
    },
    {
      title: "Reporter User Name",
      dataIndex: "reporterUserName",
      key: "reporterUserName",
      ...getColumnSearchProps("reporterUserName"),
    },
    {
      title: "Reporter User Wallet ID",
      dataIndex: "reporterUserWalletId",
      key: "reporterUserWalletId",
      ...getColumnSearchProps("reporterUserWalletId"),
    },
    {
      title: "Owner User Wallet ID",
      dataIndex: "ownerUserWalletIds",
      key: "ownerUserWalletIds",
      render: (value, record) => value.join(', '),
    },
    {
      title: "Black Listed At",
      key: "blackListedAt",
      render: (text, record) => {
        const date = moment(record.blackListedAt).format("MMMM Do YYYY, h:mm:ss a");
        return <>{date}</>;
      },
      sorter: (a, b) => {
        return moment.utc(a.blackListedAt).diff(moment.utc(b.blackListedAt));
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <>
            <Popconfirm
              title=" Are you sure you want to delete this black list?"
              onConfirm={() => onDelete(record)}
              onCancel={() => {}}
              okText="Delete"
              cancelText="No"
            >
              <Button style={{ marginLeft: 10 }} type="danger">
                Delete
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getBlackLists();
  }, [page, limit, sort, filter]);

  useEffect(() => {
    if (isDeleted) {
      getBlackLists();
      dispatch(cleanBlackLists());
    }
  }, [isDeleted, page, limit, sort, filter]);

  return (
    <div className="right-section">
      <Table
        dataSource={blackLists?.rows || []}
        onChange={handleTableChange}
        pagination={{
          pageSize: limit,
          total: blackLists?.count || 0,
          current: page,
        }}
        columns={columns}
      />
    </div>
  );
};
export default List;

import React from "react";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined
} from "@ant-design/icons";

import { connect } from "react-redux";

import { Menu, Layout, Button } from "antd";
import { Link } from "react-router-dom";

const { SubMenu } = Menu;
const { Sider } = Layout;

class PageSidebar extends React.Component {
  render() {
    if (this.props.login) {
      if (!this.props.login.isAuth) {
        return null;
      }
    }

    const urlSegment = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );

    let check = false;
    if (urlSegment === "login" || urlSegment === "admin" || window.location.href.includes('/confirm-email')) {
      check = true;
    }

    if (check) {
      return <></>;
    }

    const isAdmin = this.props.login?.roles?.includes('admin')

    return (
      <Sider width={200} className="site-layout-background">
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          style={{ height: "100%", borderRight: 0 }}
          title={
            <span>
              <UserOutlined className="sidebar-icon" />
              Users
            </span>
          }
        >
          <SubMenu key="sub3" title="Race">
            <Menu.Item key="11"><Link to="/create-race">Create</Link></Menu.Item>
            <Menu.Item key="12"><Link to="/get-races">All Races</Link></Menu.Item>
          </SubMenu>
          {isAdmin && (
            <>
              <SubMenu key="sub4" title="Auto Race Pool">
                <Menu.Item key="13"><Link to="/create-auto-race-pool">Create</Link></Menu.Item>
                <Menu.Item key="14"><Link to="/auto-race-pool-list">All Auto Race Pools</Link></Menu.Item>
              </SubMenu>

              <SubMenu key="sub5" title="Beta">
                <Menu.Item key="15"><Link to="/create-beta-user">Create</Link></Menu.Item>
                <Menu.Item key="16"><Link to="/get-beta-users">All Beta User</Link></Menu.Item>
              </SubMenu>

              <SubMenu key="sub6" title="Settings">
                <Menu.Item key="17"><Link to="/add-settings">Add Settings</Link></Menu.Item>
                <Menu.Item key="18"><Link to="/list-settings">List Settings</Link></Menu.Item>
              </SubMenu>

              <SubMenu key="sub7" title="Users">
                <Menu.Item key="120"><Link to="/list-user">List User</Link></Menu.Item>
              </SubMenu>

              <SubMenu key="sub8" title="Black Lists">
                <Menu.Item key="1"><Link to="/create-black-list">Create</Link></Menu.Item>
                <Menu.Item key="2"><Link to="/black-lists">Black Lists</Link></Menu.Item>
              </SubMenu>

            </>
          )}
        </Menu>
      </Sider>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.user.login,
  };
}

export default connect(mapStateToProps)(PageSidebar);

import { toast } from "react-toastify";
import {
  BLACK_LIST_CLEAN,
  REPLACE_BLACK_LIST_ERROR,
  REPLACE_BLACK_LIST_SUCCESS,
  GET_ALL_BLACK_LIST_SUCCESS,
} from "../../constants/blackLists";
import { backend } from "../../axios";

export async function replaceBlackList({
  stealerUserWalletId,
  contractType,
  tokenId,
  reporterUserName,
  reporterUserWalletId,
  isBlackListed,
}) {
  const req = await backend
    .put(`/api/black-lists/${stealerUserWalletId}`, {
      contractType,
      tokenId,
      reporterUserName,
      reporterUserWalletId,
      isBlackListed,
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error.response.data);
      return {
        type: "ERROR",
        error: error.response.data?.message,
      };
    });

  if (req.type === "ERROR") {
    toast.error(req.error);

    return {
      type: REPLACE_BLACK_LIST_ERROR,
      payload: req.error,
      success: false,
      error: true,
    };
  } else {
    toast.success(`Blacklist for "${tokenId}" ${isBlackListed ? 'Created' : 'Deleted'} Successfully`);

    return {
      type: REPLACE_BLACK_LIST_SUCCESS,
      success: true,
      error: false,
    };
  }
}

export async function getAllBlackLists({ page, limit, filter, sort }) {
  const req = await backend
    .get('/api/black-lists/', {
      params: {
        page,
        limit,
        filter,
        sort,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      toast.error(error.message);
    });
  if (req) {
    return {
      type: GET_ALL_BLACK_LIST_SUCCESS,
      payload: req,
      error: false,
      success: false,
    };
  }
}

export async function cleanBlackLists() {
  return {
    type: BLACK_LIST_CLEAN,
  };
}

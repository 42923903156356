import React, { useEffect, useState } from "react";
import { Input, Space, Typography, Table, Button, Alert, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllSettingAction } from "../../actions/setting/getAllSettingAction";
import { DETAIL_SETTING_RESET } from "../../constants/settingConstants";
import { deleteSettingAction } from "../../actions/setting/deleteSettingAction";
import { getDetailSettingAction } from "../../actions/setting/getSettingAction";
import { updateSettingAction } from "../../actions/setting/updateSettingAction";
import { SearchOutlined } from '@ant-design/icons';
const { Title } = Typography;

function ListSettings() {
  const [state, setState] = useState({
    searchText: '',
    searchedColumn: '',
  })
  const dispatch = useDispatch();

  const { allSetting, success, error } = useSelector(
    (state) => state.getAllSettingReducer
  );
  const { success: deleteSuccess, error: deleteError } = useSelector(
    (state) => state.deleteSettingReducer
  );
  const {
    success: detailSuccess,
    error: detailError,
    detailSetting,
  } = useSelector((state) => state.getDetailSettingReducer);

  const {
    updateSetting,
    success: updateSuccess,
    error: updateError,
  } = useSelector((state) => state.updateSettingReducer);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const updateSettingHandler = async (id) => {
    dispatch({
      type: DETAIL_SETTING_RESET,
    });
    dispatch(getDetailSettingAction(id));
    showModal();
  };

  const handleOk = async () => {
    dispatch(updateSettingAction(id, name, value, message));
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  let searchInput;
  
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
           searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() =>handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() =>handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
             setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() =>searchInput.select(), 100);
      }
    }
  })


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
   setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = clearFilters => {
    clearFilters();
   setState({ searchText: '' });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps('name'),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <>
            <Button
              onClick={() => updateSettingHandler(record.id)}
              style={{ marginLeft: 10 }}
              type="primary"
            >
              Update
            </Button>
            <Button
              onClick={() => {
                dispatch(deleteSettingAction(record.id));
              }}
              style={{ marginLeft: 10 }}
              type="danger"
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getAllSettingAction());
    if (deleteSuccess) {
      dispatch(getAllSettingAction());
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (allSetting) {
    }
  }, [success]);

  useEffect(() => {
    if (detailSetting && detailSuccess) {
      setId(detailSetting?.id);
      setName(detailSetting?.name);
      setValue(detailSetting?.value);
      setMessage(detailSetting?.message);
    }
  }, [detailSuccess]);

  useEffect(() => {
    if (updateSuccess && updateSetting) {
      dispatch(getAllSettingAction());
    }
  }, [updateSetting, updateSuccess]);

  return (
    <div className="addSettings" style={{ marginTop: "100px" }}>
      {error && (
        <Alert
          message={error}
          style={{ width: "80%" }}
          type="error"
          showIcon
          closable
        />
      )}

      {updateError && (
        <Alert
          message={error}
          style={{ width: "80%" }}
          type="error"
          showIcon
          closable
        />
      )}

      {updateSuccess && (
        <Alert
          message="Updated Successfully"
          style={{ width: "80%" }}
          type="success"
          showIcon
          closable
        />
      )}

      {deleteSuccess && (
        <Alert
          message="Deleted Successfully"
          style={{ width: "80%" }}
          type="success"
          showIcon
          closable
        />
      )}


      <div className="listSettings">
        <Title>Settings List</Title>
      </div>
      <Table
        style={{ width: "95%" }}
        dataSource={allSetting}
        columns={columns}
        rowKey={"id"}
      />

      <Modal
        title="Update Beta User"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        Name :{" "}
        <Input
          placeholder="Update Setting Name"
          onChange={(e) => setName(e.target.value)}
          name="name"
          value={name}
        />
        Value :{" "}
        <Input
          placeholder="Update Setting Value"
          onChange={(e) => setValue(e.target.value)}
          name="value"
          value={value}
        />
        Message :{" "}
        <Input
          placeholder="Update Setting Message"
          onChange={(e) => setMessage(e.target.value)}
          name="message"
          value={message}
        />
      </Modal>
    </div>
  );
}

export default ListSettings;

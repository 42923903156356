export const GET_ALL_BETA_USERS_REQUEST = "GET_ALL_BETA_USERS_REQUEST"
export const GET_ALL_BETA_USERS_SUCCESS = "GET_ALL_BETA_USERS_SUCCESS"
export const GET_ALL_BETA_USERS_FAIL = "GET_ALL_BETA_USERS_FAIL"

export const ADD_BETA_USERS_REQUEST = "ADD_BETA_USERS_REQUEST"
export const ADD_BETA_USERS_SUCCESS = "ADD_BETA_USERS_SUCCESS"
export const ADD_BETA_USERS_FAIL = "ADD_BETA_USERS_FAIL"
export const ADD_BETA_USERS_RESET = "ADD_BETA_USERS_RESET"

export const DELETE_BETA_USER_REQUEST = "DELETE_BETA_USER_REQUEST"
export const DELETE_BETA_USER_SUCCESS = "DELETE_BETA_USER_SUCCESS"
export const DELETE_BETA_USER_FAIL = "DELETE_BETA_USER_FAIL"
export const DELETE_BETA_USER_RESET = "DELETE_BETA_USER_RESET"

import React, { useEffect, useMemo, useRef, useState } from "react";
import { Table, Button, Input, Popconfirm, Form, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Space, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import {
  MAX_ENTRY_FEE_FOR_RACE,
  raceDistanceList,
  raceGroupList,
  racePaidStatusList,
  racePeckingOrderList,
  raceStatusList,
  raceTerrainList,
  raceTypeList,
  raceCoinTypeList,
  bawkStakingCompanyList,
} from "../../constants/raceContants";
import { cleanAutoPoolRace } from "../../actions/autoRacePool";
import { truncateToDecimals } from "../../utils";
import { deleteRace, getAllRaces, updateRace } from "../../actions/race/race";
import { websiteUrl } from "../../constants";
import './list.css';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  layout: "horizontal",
  size: 100,
};

const List = () => {
  const [form] = Form.useForm();

  const user = useSelector((state) => state.user.login);
  const isPartner = useMemo(() => user?.roles?.includes('partner'), [user])
  const [maxEntryFee, setMaxEntryFee] = useState(MAX_ENTRY_FEE_FOR_RACE[raceCoinTypeList[0]])

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [sort, setSort] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBtn, setModalBtn] = useState("");
  const [maxPrizePool, setMaxPrizePool] = useState();
  const [raceId, setRaceId] = useState();
  let searchInput = useRef();

  const dispatch = useDispatch();
  const races = useSelector((state) => state.race.races);
  const isUpdated = useSelector((state) => state.race.isUpdated);
  const isDeleted = useSelector((state) => state.race.isDeleted);

  const onUpdate = (record) => {
    setRaceId(record.id);
    form.setFieldsValue({
      id: record.id,
      name: record.name,
      peckingOrder: record.peckingOrder,
      terrainId: record.terrainId,
      status: record.status,
      distance: record.distance,
      currentCapacity: record.currentCapacity,
      location: record.location,
      maxCapacity: record.maxCapacity,
      minimumStartDelay: record.minimumStartDelay,
      startTime: record.startTime,
      category: record.category,
      fee: record.fee,
      prizePool: record.prizePool,
      unlimitPO: record.unlimitPO,
      type: record.type,
      group: record.group,
      allowedUserWalletIds: record.allowedUserWalletIds,
      allowedChickenIds: record.allowedChickenIds,
      coinType: record.coinType,
      bawkStakingCompanyId: record.bawkStakingCompanyId,
    });
    setModalVisible(true);
    setModalTitle("Update Race");
    setMaxEntryFee(MAX_ENTRY_FEE_FOR_RACE[record.coinType]);
  };

  const onDelete = (id) => {
    dispatch(deleteRace(id));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilter({});
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sort = sorter.columnKey
      ? {
        field: sorter.columnKey,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      }
      : undefined;

    setPage(pagination.current);
    setLimit(pagination.pageSize);
    setFilter(filters);
    setSort(sort);
  };

  const fetchRaces = () => {
    dispatch(
      getAllRaces({
        page,
        limit,
        filter: {
          ...filter,
          status: filter.status?.length ? filter.status : raceStatusList.map((item) => item.value),
          userId: isPartner ? user?.id : undefined
        },
        sort,
      })
    );
  };

  const onSubmitUpdate = (values) => {
    setModalBtn("");
    setModalVisible(false);

    dispatch(
      updateRace({
        ...values,
        updateId: raceId,
      })
    );
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setModalBtn("");
    setModalVisible(false);
  };

  const onChangeMaxCapacity = (value) => {
    const { fee } = form.getFieldsValue();
    const prizePool = truncateToDecimals(fee * value * 0.9, 6);
    form.setFieldsValue({
      maxCapacity: value,
      prizePool,
    });

    calcMaxPrizePool(fee, value);
  };

  const onChangeFee = (value) => {
    const { maxCapacity } = form.getFieldsValue();
    const prizePool = truncateToDecimals(value * maxCapacity * 0.9, 6);

    form.setFieldsValue({
      fee: value,
      prizePool,
    });

    calcMaxPrizePool(value, maxCapacity);
  };

  const calcMaxPrizePool = (fee, maxCapacity) => {
    if (!fee || !maxCapacity) {
      setMaxPrizePool();

      return;
    }

    setMaxPrizePool(truncateToDecimals(fee * maxCapacity * 0.9, 6));
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        if (isPartner) {
          return <Button
            onClick={() => window.open(`${websiteUrl}/race-detail/${record.id}`)}
            style={{ marginLeft: 10 }}
            type="primary"
          >
            Open
          </Button>
        }
        return (
          <>
            <Button
              onClick={() => onUpdate(record)}
              style={{ marginLeft: 10 }}
              type="primary"
            >
              Update
            </Button>

            <Popconfirm
              title="Are you sure you want to delete this race?"
              onConfirm={() => onDelete(record.id)}
              onCancel={() => { }}
              okText="Delete"
              cancelText="No"
            >
              <Button style={{ marginLeft: 10 }} type="danger">
                Delete
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
    {
      title: "Race Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Company",
      dataIndex: "bawkStakingCompanyId",
      key: "company",
      filters: bawkStakingCompanyList,
      render: (text, record) => {
        const type = bawkStakingCompanyList.find((t) => t.value === text);
        return type?.text;
      },
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
      filters: raceDistanceList.map((distance) => ({
        text: distance.toString(),
        value: distance,
      })),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Terrain",
      dataIndex: "terrainId",
      key: "terrain",
      filters: raceTerrainList,
      render: (text, record) => {
        const type = raceTerrainList.find((t) => t.value === text);
        return type?.text;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: raceStatusList,
      render: (text, record) => {
        const status = raceStatusList.find((t) => t.value === text);
        return status?.text;
      },
    },
    {
      title: "Pecking Order",
      dataIndex: "peckingOrder",
      key: "peckingOrder",
      filters: racePeckingOrderList.map((p) => ({ text: p, value: p })),
    },
    {
      title: "Entry Fee",
      dataIndex: "fee",
      key: "fee",
    },
    {
      title: "Prize Pool",
      dataIndex: "prizePool",
      key: "prizePool",
    },
    {
      title: "Capacity",
      dataIndex: "maxCapacity",
      key: "maxCapacity",
    },
    {
      title: "Unlimit PO",
      dataIndex: "unlimitPO",
      key: "unlimitPO",
      filters: [
        {
          text: "No",
          value: 0,
        },
        {
          text: "Yes",
          value: 1,
        },
      ],
      render: (text, record) => {
        switch (text) {
          case 0:
            return "No";

          case 1:
            return "Yes";

          default:
            break;
        }
      },
    },
    {
      ...(isPartner ? [] : [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          filters: raceTypeList,
          render: (value, record) => {
            const type = raceTypeList.find((t) => t.value === value);
            return type.text;
          },
        },
        {
          title: "Group",
          dataIndex: "group",
          key: "group",
          filters: raceGroupList.map((group) => ({
            text: group,
            value: group,
          })),
        },
        {
          title: "Paid Status",
          dataIndex: "paidStatus",
          key: "paidStatus",
          filters: racePaidStatusList,
          render: (value, record) => {
            const type = racePaidStatusList.find((t) => t.value === value);
            return type.text;
          },
        }
      ])
    },
    {
      title: "Allowed User Wallets",
      dataIndex: "allowedUserWalletIds",
      key: "allowedUserWalletIds",
      ...getColumnSearchProps("allowedUserWalletIds"),
      render: (text, record) => {
        return <Space className="allowed-user-wallet-ids">{text}</Space>;
      },
    },
    {
      title: "Allowed Chickens",
      dataIndex: "allowedChickenIds",
      key: "allowedChickenIds",
      ...getColumnSearchProps("allowedChickenIds"),
      render: (text, record) => {
        return <Space className="allowed-chicken-ids">{text}</Space>;
      },
    },
    {
      title: "Created At",
      key: "createdAt",
      render: (text, record) => {
        const date = moment(record.createdAt).format("MMMM Do YYYY, h:mm:ss a");
        return <>{date}</>;
      },
      sorter: (a, b) => {
        return moment.utc(a.createdAt).diff(moment.utc(b.createdAt));
        // return  a.createdAt - b.createdAt
      },
    },
  ].filter(column => !!column.key);

  useEffect(() => {
    fetchRaces();
  }, [page, limit, sort, filter]);

  useEffect(() => {
    if (isUpdated || isDeleted) {
      fetchRaces();
      dispatch(cleanAutoPoolRace());
    }
  }, [isUpdated, isDeleted, page, limit, sort, filter]);

  return (
    <div className="race-list right-section">
      <Table
        dataSource={races?.rows || []}
        onChange={handleTableChange}
        pagination={{
          pageSize: limit,
          total: races?.count || 0,
          current: page,
        }}
        columns={columns}
      />
      <Modal
        title={modalTitle}
        visible={modalVisible}
        onOk={handleOk}
        okText={modalBtn}
        onCancel={handleCancel}
      >
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onSubmitUpdate}
        >
          <Form.Item label="Race Name" name="name" rules={[{ required: true }]}>
            <Input placeholder="Enter Race Name" />
          </Form.Item>

          <Form.Item
            label="Company"
            name="bawkStakingCompanyId"
            rules={[{ required: true }]}
          >
            <Select>
              {bawkStakingCompanyList.map((company) => (
                <Option value={company.value}>{company.text}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Terrain"
            name="terrainId"
            rules={[{ required: true }]}
          >
            <Select>
              {raceTerrainList.map((terrain) => (
                <Option value={terrain.value}>{terrain.text}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Status" name="status" rules={[{ required: true }]}>
            <Select>
              {raceStatusList.map((terrain) => (
                <Option value={terrain.value}>{terrain.text}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Pecking Order"
            name="peckingOrder"
            rules={[{ required: true }]}
          >
            <Select>
              {racePeckingOrderList.map((peckOrder) => (
                <Option key={peckOrder}>{peckOrder}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Distance"
            name="distance"
            rules={[{ required: true }]}
          >
            <Select>
              {raceDistanceList.map((distance) => (
                <Option value={distance}>{distance}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Race location"
            name="location"
            rules={[{ required: true }]}
          >
            <Input placeholder="Enter location" />
          </Form.Item>

          <Form.Item
            label="Max Capacity"
            name="maxCapacity"
            rules={[{ required: true, min: 3, max: 12, type: "number" }]}
          >
            <InputNumber
              step={1}
              disabled
              style={{ width: "100%" }}
              placeholder="Enter maxCapacity"
              onChange={(value) => onChangeMaxCapacity(value)}
            />
          </Form.Item>

          <Form.Item
            label="Minimum Start Delay"
            name="minimumStartDelay"
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Minimum Start Delay"
            />
          </Form.Item>

          <Form.Item
            label="Race Start Time (seconds)"
            name="startTime"
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Race Start Time by seconds"
            />
          </Form.Item>

          <Form.Item
            label="Entry Fee"
            name="fee"
            rules={[{ required: true, min: 0, max: maxEntryFee, type: "number" }]}
          >
            <InputNumber
              placeholder="Entry Fee"
              style={{ width: "100%" }}
              onChange={(value) => onChangeFee(value)}
            />
          </Form.Item>

          <Form.Item
            label="Prize Pool"
            name="prizePool"
            rules={[
              {
                required: true,
                type: "number",
                min: 0,
                max: maxPrizePool,
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} placeholder="Prize Pool" />
          </Form.Item>

          <Form.Item label="UnlimitPO" name="unlimitPO">
            <Select>
              <Option value={0}>No</Option>
              <Option value={1}>Yes</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Type" name="type" rules={[{ required: true }]}>
            <Select>
              {raceTypeList.map((type) => (
                <Option value={type.value}>{type.text}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Group" name="group" rules={[{ required: true, min: 0, max: 99, type: "number", step: 1 }]}>
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Group"
              type="number"
            />
          </Form.Item>

          <Form.Item label="Allowed User Wallets" name="allowedUserWalletIds">
            <Input placeholder="Enter allowed user wallets separated by comma" />
          </Form.Item>

          <Form.Item label="Allowed Chickens" name="allowedChickenIds">
            <Input placeholder="Enter allowed chicken ids separated by comma" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
export default List;

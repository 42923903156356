import { backend } from "../../axios";
import { DELETE_SETTING_FAIL, DELETE_SETTING_REQUEST, DELETE_SETTING_RESET, DELETE_SETTING_SUCCESS } from "../../constants/settingConstants";

export const deleteSettingAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SETTING_REQUEST });

    const response = await backend.delete(`/api/settings/${id}`);

    dispatch({
      type: DELETE_SETTING_SUCCESS,
      payload: response,
    });

    setTimeout(() => {
      dispatch({
        type: DELETE_SETTING_RESET,
      });
    }, 2000);
   
  } catch (error) {
    dispatch({
      type: DELETE_SETTING_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

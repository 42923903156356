import { ADD_SETTING_FAIL, ADD_SETTING_REQUEST, ADD_SETTING_RESET, ADD_SETTING_SUCCESS, DELETE_SETTING_FAIL, DELETE_SETTING_REQUEST, DELETE_SETTING_RESET, DELETE_SETTING_SUCCESS, DETAIL_SETTING_FAIL, DETAIL_SETTING_REQUEST, DETAIL_SETTING_SUCCESS, GET_ALL_SETTINGS_FAIL, GET_ALL_SETTINGS_REQUEST, GET_ALL_SETTINGS_SUCCESS, UPDATE_SETTING_FAIL, UPDATE_SETTING_REQUEST, UPDATE_SETTING_RESET, UPDATE_SETTING_SUCCESS } from "../constants/settingConstants"

export const addSettingReducer = (state = {}, action) => {
    
    switch (action.type) {
        case ADD_SETTING_REQUEST :
            return { loading:true}
           
        case ADD_SETTING_SUCCESS :
            return {
                loading: false,
                addSetting: action.payload,
                success:true
            }
        
        case ADD_SETTING_FAIL :
            return { loading: false, error : action.payload }
        
        case ADD_SETTING_RESET:
            return {}

        default:
            return state
    }
}

export const getAllSettingReducer = (state = {}, action) => {
    
    switch (action.type) {
        case GET_ALL_SETTINGS_REQUEST :
            return { loading:true}
           
        case GET_ALL_SETTINGS_SUCCESS :
            return {
                loading: false,
                allSetting: action.payload,
                success:true
            }
        
        case GET_ALL_SETTINGS_FAIL :
            return { loading: false, error : action.payload }
        

        default:
            return state
    }
}


export const deleteSettingReducer = (state = {}, action) => {
    
    switch (action.type) {
        case DELETE_SETTING_REQUEST :
            return { loading:true}
           
        case DELETE_SETTING_SUCCESS :
            return {
                loading: false,
             
                success:true
            }
        
        case DELETE_SETTING_FAIL :
            return { loading: false, error : action.payload }
        
        case DELETE_SETTING_RESET:
            return {}

        default:
            return state
    }
}

export const getDetailSettingReducer = (state = {}, action) => {
    
    switch (action.type) {
        case DETAIL_SETTING_REQUEST :
            return { loading:true}
           
        case DETAIL_SETTING_SUCCESS :
            return {
                loading: false,
                detailSetting: action.payload,
                success:true
            }
        
        case DETAIL_SETTING_FAIL :
            return { loading: false, error : action.payload }
        

        default:
            return state
    }
}


export const updateSettingReducer = (state = {}, action) => {
    
    switch (action.type) {
        case UPDATE_SETTING_REQUEST :
            return { loading:true}
           
        case UPDATE_SETTING_SUCCESS :
            return {
                loading: false,
                updateSetting: action.payload,
                success:true
            }
        
        case UPDATE_SETTING_FAIL :
            return { loading: false, error : action.payload }
        
        case UPDATE_SETTING_RESET:
            return {}

        default:
            return state
    }
}
import { toast } from "react-toastify";
import {
  CREATE_RACE_ERROR,
  CREATE_RACE_SUCCESS,
  DELETE_RACE_ERROR,
  DELETE_RACE_SUCCESS,
  GET_ALL_RACE_SUCCESS,
  RACE_CLEAN,
  UPDATE_RACE_ERROR,
  UPDATE_RACE_SUCCESS,
} from "../../constants/raceContants";
import { getCookie } from "../user";
import { backend } from "./../../axios";

export async function createRace({
  name,
  peckingOrder,
  terrainId,
  distance,
  maxCapacity,
  currentCapacity,
  location,
  minimumStartDelay,
  startTime,
  category,
  fee,
  prizePool,
  unlimitPO,
  type,
  group,
  allowedUserWalletIds,
  allowedChickenIds,
  coinType,
  bawkStakingCompanyId,
}) {
  if (unlimitPO === "Yes") unlimitPO = 1;
  if (unlimitPO === "No") unlimitPO = 0;
  
  allowedUserWalletIds = allowedUserWalletIds?.replace(/\s*/g, '');
  allowedChickenIds = allowedChickenIds?.replace(/\s*/g, '');

  const req = await backend
    .post('/api/races', {
      name,
      peckingOrder,
      terrainId,
      distance,
      maxCapacity,
      currentCapacity,
      location,
      minimumStartDelay,
      startTime,
      category,
      fee,
      prizePool,
      unlimitPO,
      type,
      group,
      allowedUserWalletIds,
      allowedChickenIds,
      coinType,
      bawkStakingCompanyId,
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return {
        type: "ERROR",
        error: error.response.data?.message,
      };
    });

  if (req.type === "ERROR") {
    toast.error(req.error);

    return {
      type: CREATE_RACE_ERROR,
      payload: req.error,
      success: false,
      error: true,
    };
  } else {
    toast.success(`Race "${name}" Created Successfully`);

    return {
      type: CREATE_RACE_SUCCESS,
      success: true,
      error: false,
    };
  }
}

export async function getAllRaces({ page, limit, filter, sort }) {
  const req = await backend
    .get('/api/races', {
      params: {
        page,
        limit,
        filter,
        sort,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      toast.error(error.message);
    });

  if (req) {
    return {
      type: GET_ALL_RACE_SUCCESS,
      payload: req,
      error: false,
      success: true,
    };
  }
}

export async function updateRace({
  updateId: id,
  name,
  peckingOrder,
  terrainId,
  distance,
  currentCapacity,
  location,
  minimumStartDelay,
  startTime,
  status,
  category,
  fee,
  prizePool,
  unlimitPO,
  type,
  group,
  allowedUserWalletIds,
  allowedChickenIds,
  coinType,
  bawkStakingCompanyId,
}) {
  const token = getCookie("auth");

  if (unlimitPO === "Yes") unlimitPO = 1;
  if (unlimitPO === "No") unlimitPO = 0;
  
  allowedUserWalletIds = allowedUserWalletIds?.replace(/\s*/g, '');
  allowedChickenIds = allowedChickenIds?.replace(/\s*/g, '');

  const req = await backend
    .patch(
      `/api/races/${id}`,
      {
        name,
        peckingOrder,
        terrainId,
        distance,
        currentCapacity,
        location,
        minimumStartDelay,
        startTime,
        status,
        category,
        fee,
        prizePool,
        unlimitPO,
        type,
        group,
        allowedUserWalletIds,
        allowedChickenIds,
        coinType,
        bawkStakingCompanyId,
      },
      { token }
    )
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return {
        type: "ERROR",
        payload: error.response.data,
      };
    });
  if (req.type === "ERROR") {
    toast.error(req.error);

    return {
      type: UPDATE_RACE_ERROR,
      payload: req.payload,
      error: true,
    };
  } else {
    toast.success(`Race "${name}" Updated Successfully`);

    return {
      type: UPDATE_RACE_SUCCESS,
      payload: req,
      error: false,
    };
  }
}

export async function deleteRace(id) {
  const req = await backend
    .delete(`/api/races/${id}`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return {
        type: "ERROR",
        error: error.response.data?.message,
      };
    });

  if (req.type === "ERROR") {
    toast.error(req.error);

    return {
      type: DELETE_RACE_ERROR,
      payload: req.error,
      success: false,
      error: true,
    };
  } else {
    toast.success(`Deleted Race Successfully`);

    return {
      type: DELETE_RACE_SUCCESS,
      payload: true,
      success: true,
      error: false,
    };
  }
}

export async function cleanRace() {
  return {
    type: RACE_CLEAN,
  };
}

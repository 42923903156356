
import { backend } from "../../axios";
import {
  ADD_BETA_USERS_FAIL,
  ADD_BETA_USERS_REQUEST,
  ADD_BETA_USERS_RESET,
  ADD_BETA_USERS_SUCCESS,
  DELETE_BETA_USER_FAIL,
  DELETE_BETA_USER_REQUEST,
  DELETE_BETA_USER_RESET,
  DELETE_BETA_USER_SUCCESS,
  GET_ALL_BETA_USERS_FAIL,
  GET_ALL_BETA_USERS_REQUEST,
  GET_ALL_BETA_USERS_SUCCESS,
} from "../../constants/betaUserConstants";

// action to get all betaUsers

export const getAllBetaUsersAction = ({ page, limit, filter, sort }) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_BETA_USERS_REQUEST });

    const res = await backend.get('/api/beta-users', {
      params: {
        page,
        limit,
        filter,
        sort,
      },
    });

    dispatch({
      type: GET_ALL_BETA_USERS_SUCCESS,
      payload: res?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BETA_USERS_FAIL,
      payload: error?.response?.data?.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const addBetaUserAction = (userWalletId) => async (dispatch) => {
  try {
    dispatch({ type: ADD_BETA_USERS_REQUEST });

    const response = await backend.patch(`/api/beta-users/${userWalletId}`, {
      isBetaUser: true,
    });

    dispatch({
      type: ADD_BETA_USERS_SUCCESS,
      payload: response,
    });

    setTimeout(() => {
      dispatch({
        type: ADD_BETA_USERS_RESET,
      });
    }, 3000);
  } catch (err) {
    dispatch({
      type: ADD_BETA_USERS_FAIL,
      payload: err?.response?.data?.message ? err.response.data.message : err.message,
    });
  }
};

export const deleteBetaUserAction = (userWalletId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_BETA_USER_REQUEST });

    const response = await backend.patch(`/api/beta-users/${userWalletId}`, {
      isBetaUser: false,
    });

    dispatch({
      type: DELETE_BETA_USER_SUCCESS,
      payload: response,
    });

    setTimeout(() => {
      dispatch({
        type: DELETE_BETA_USER_RESET,
      });
    }, 3000);
  } catch (error) {
    dispatch({
      type: DELETE_BETA_USER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { logout } from "../../actions/user";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { backend } from "../../axios";
import "./user_verify.css";

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

function UserVerifyLayout() {
  const param = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const verifyAccount = async () => {
    setIsLoading(true);
    try {
      await backend.get(`/confirm-email/${param.token}`);
      toast.success("Verified email successfully");
      setTimeout(() => {
        dispatch(logout());  
        history.push('/login')
      }, 2000)
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    verifyAccount()
  }, [param])

  return (
    <div className="user_verify-container">
      {isLoading && (
        <React.Fragment>
          <Spin indicator={antIcon} />
          <div>Verifying email</div>
        </React.Fragment>
      )}
    </div>
  );
}

export default UserVerifyLayout;

import axios from "axios";
import { logout } from "./actions/user";
import { store } from "./store";

export const backend = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:8081',
    withCredentials: true,
});

 backend.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        const state = store.getState()
        const isLogged = state.user?.login?.isAuth
        if (isLogged) {
            store.dispatch(logout())
        }
      }
  
      return Promise.reject(error);
    }
  )
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Switch, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";

import "./create_beta_user.css";
import { addBetaUserAction } from "../../actions/beta/beta";

function CreateBetaUserLayout() {
  const dispatch = useDispatch();
  const [userAddress, setUserAddress] = useState("");
  const { betaUser, success, error } = useSelector(
    (state) => state.addBetaUserReducer
  );

  const onFinish = (value) => {
    if (!userAddress) {
      alert("Please Enter Public Address");
      return;
    }

    dispatch(addBetaUserAction(userAddress));
  };

  useEffect(()=>{

    if(success&& betaUser){
      setUserAddress("")
    }
  },[userAddress, success]);

  return (
    <div className="right-section">
      {error && <Alert message={error} type="error" showIcon />}
      {success && (
        <Alert
          message={"Successfully Created Beta User"}
          type="success"
          showIcon
        />
      )}
      <div className="createBetaUserLayout__heading">
        <h1 style={{ textAlign: "center", marginTop: 20 }}> Add Beta User </h1>
      </div>

      <div className="createBetaUserLayout__form">
        <Form
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="horizontal"
          initialValues={{
            size: 100,
          }}
          size={50}
        >
          <Form.Item
            label="User Address"
            name="user_Address"
            rules={[{ required: true, message: "Please input userAddress !" }]}
            onChange={(e) => setUserAddress(e.target.value)}
            style={{ marginTop: 50 }}
            value={userAddress}
          >
            <Input />
          </Form.Item>

          <Form.Item style={{ justifyContent: "center", textAlign: "center" }}>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default CreateBetaUserLayout;

import { backend } from "../../axios";
import { UPDATE_SETTING_FAIL, UPDATE_SETTING_REQUEST, UPDATE_SETTING_RESET, UPDATE_SETTING_SUCCESS } from "../../constants/settingConstants";


export const updateSettingAction = (id, name, value, message) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SETTING_REQUEST });

    const response = await backend.patch(`/api/settings/${id}`, {
      name,
      value,
      message,
    });

    dispatch({
      type: UPDATE_SETTING_SUCCESS,
      payload: response,
    });

    setTimeout(() => {
      dispatch({
        type: UPDATE_SETTING_RESET,
      });
    }, 2000);
  } catch (error) {
    dispatch({
      type: UPDATE_SETTING_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

import {
  CREATE_RACE_SUCCESS,
  CREATE_RACE_ERROR,
  UPDATE_RACE_ERROR,
  DELETE_RACE_ERROR,
  GET_ALL_RACE_SUCCESS,
  DELETE_RACE_SUCCESS,
  UPDATE_RACE_SUCCESS,
  RACE_CLEAN,
} from "../constants/raceContants";

export default function raceReducer(
  state = {},
  { payload, type, error, success }
) {
  switch (type) {
    case CREATE_RACE_SUCCESS:
      return { ...state, isSuccess: success, isError: error };
    case CREATE_RACE_ERROR:
    case UPDATE_RACE_ERROR:
    case DELETE_RACE_ERROR:
      return {
        ...state,
        alertMsg: payload,
        isError: error,
        isSuccess: success,
      };
    case "GET_CHICKENS":
      return { ...state, chickens: payload };
    case GET_ALL_RACE_SUCCESS:
      return {
        ...state,
        races: payload,
        isError: error,
        isSuccess: success,
        isDeleted: false,
        isUpdated: false,
      };
    case DELETE_RACE_SUCCESS:
      return { ...state, isDeleted: payload };
    case UPDATE_RACE_SUCCESS:
      return { ...state, isUpdated: payload, isError: error };
    case RACE_CLEAN:
      return {
        isUpdated: false,
        isDeleted: false,
        isError: false,
        isSuccess: false,
      };

    default:
      return state;
  }
}

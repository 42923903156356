import { backend } from "../../axios";
import { DETAIL_SETTING_FAIL, DETAIL_SETTING_REQUEST, DETAIL_SETTING_SUCCESS } from "../../constants/settingConstants";

export const getDetailSettingAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: DETAIL_SETTING_REQUEST });
    const response = await backend.get(`/api/settings/${id}`);

    dispatch({
      type: DETAIL_SETTING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: DETAIL_SETTING_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

import { toast } from "react-toastify";
import {
  AUTO_RACE_POOL_CLEAN,
  CREATE_AUTO_RACE_POOL_ERROR,
  CREATE_AUTO_RACE_POOL_SUCCESS,
  DELETE_AUTO_RACE_POOL_ERROR,
  DELETE_AUTO_RACE_POOL_SUCCESS,
  GET_ALL_AUTO_RACE_POOL_SUCCESS,
  UPDATE_AUTO_RACE_POOL_ERROR,
  UPDATE_AUTO_RACE_POOL_SUCCESS,
} from "../../constants/autoRacePool";
import { backend } from "./../../axios";

export async function createAutoRacePool({
  name,
  peckingOrder,
  terrainId,
  distance,
  maxCapacity,
  location,
  minimumStartDelay,
  startTime,
  fee,
  prizePool,
  unlimitPO,
  type,
  group,
  coinType,
  bawkStakingCompanyId,
}) {
  if (unlimitPO === "Yes") unlimitPO = 1;
  if (unlimitPO === "No") unlimitPO = 0;

  const req = await backend
    .post('/api/auto-race-pools', {
      name,
      peckingOrder,
      terrainId,
      distance,
      maxCapacity,
      location,
      minimumStartDelay,
      startTime,
      fee,
      prizePool,
      unlimitPO,
      type,
      group,
      coinType,
      bawkStakingCompanyId,
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error.response.data);
      return {
        type: "ERROR",
        error: error.response.data?.message,
      };
    });

  if (req.type === "ERROR") {
    toast.error(req.error);

    return {
      type: CREATE_AUTO_RACE_POOL_ERROR,
      payload: req.error,
      success: false,
      error: true,
    };
  } else {
    toast.success(`Auto Race Pool "${name}" Created Successfully`);

    return {
      type: CREATE_AUTO_RACE_POOL_SUCCESS,
      success: true,
      error: false,
    };
  }
}

export async function updateAutoRacePool({
  id,
  name,
  peckingOrder,
  terrainId,
  distance,
  maxCapacity,
  location,
  minimumStartDelay,
  startTime,
  fee,
  prizePool,
  unlimitPO,
  type,
  group,
  coinType,
  bawkStakingCompanyId,
}) {
  const req = await backend
    .patch(`/api/auto-race-pools/${id}`, {
      name,
      peckingOrder,
      terrainId,
      distance,
      maxCapacity,
      location,
      minimumStartDelay,
      startTime,
      fee,
      prizePool,
      unlimitPO,
      type,
      group,
      coinType,
      bawkStakingCompanyId,
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return {
        type: "ERROR",
        error: error.response.data?.message,
      };
    });

  if (req.type === "ERROR") {
    toast.error(req.error);

    return {
      type: UPDATE_AUTO_RACE_POOL_ERROR,
      payload: req.error,
      success: false,
      error: true,
    };
  } else {
    toast.success(`Auto Race Pool "${name}" Updated Successfully`);

    return {
      type: UPDATE_AUTO_RACE_POOL_SUCCESS,
      payload: true,
      success: true,
      error: false,
    };
  }
}

export async function deleteAutoRacePool(id) {
  const req = await backend
    .delete(`/api/auto-race-pools/${id}`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return {
        type: "ERROR",
        error: error.response.data?.message,
      };
    });

  if (req.type === "ERROR") {
    toast.error(req.error);

    return {
      type: DELETE_AUTO_RACE_POOL_ERROR,
      payload: req.error,
      success: false,
      error: true,
    };
  } else {
    toast.success(`Deleted Auto Race Pool Successfully`);

    return {
      type: DELETE_AUTO_RACE_POOL_SUCCESS,
      payload: true,
      success: true,
      error: false,
    };
  }
}

export async function getAllAutoPoolRaces({ page, limit, filter, sort }) {
  const req = await backend
    .get('/api/auto-race-pools/', {
      params: {
        page,
        limit,
        filter,
        sort,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      toast.error(error.message);
    });
  if (req) {
    return {
      type: GET_ALL_AUTO_RACE_POOL_SUCCESS,
      payload: req,
      error: false,
      success: false,
    };
  }
}

export async function cleanAutoPoolRace() {
  return {
    type: AUTO_RACE_POOL_CLEAN,
  };
}

import React from "react";
import { Switch, Route } from "react-router-dom";

import PageLayout from "./hoc/layout";
import Auth from "./hoc/auth";

import Login from "./components/login/login";
import CreateRace from "./components/race/create_race";
import GetAllRaces from "./components/race/all_races";
import AllBetaUsers from "./components/beta/all_beta_users";
import CreateBetaUser from "./components/beta/create_beta_user";
import AddSettings from "./components/settings/AddSettings";
import ListSettings from "./components/settings/ListSettings";
import UserList from "./containers/user/get_all_users";
import UserVerify from "./containers/user/user_verify";
import CreateAutoRacePool from "./containers/autoRacePool/create";
import AutoRacePoolList from "./containers/autoRacePool/list";
import CreateBlackList from "./containers/blackLists/create";
import BlackLists from './containers/blackLists/list';

const NotFound = () => <div>Page not found</div>;

const Routes = () => {
  return (
    <PageLayout>
      <Switch>
        <Route path="/confirm-email/:token" exact component={UserVerify} />
        <Route path="/login" exact component={Auth(Login, false)} />
        <Route path="/" exact component={Auth(GetAllRaces, true)} />
        <Route path="/get-races" exact component={Auth(GetAllRaces, true, false)} />
        <Route path="/create-race" exact component={Auth(CreateRace, true, false)} />
        <Route
          path="/create-auto-race-pool"
          exact
          component={Auth(CreateAutoRacePool, true)}
        />
        <Route
          path="/auto-race-pool-list"
          exact
          component={Auth(AutoRacePoolList, true)}
        />
        <Route
          path="/create-beta-user"
          exact
          component={Auth(CreateBetaUser, true)}
        />
        <Route
          path="/get-beta-users"
          exact
          component={Auth(AllBetaUsers, true)}
        />
        <Route path="/add-settings" exact component={Auth(AddSettings, true)} />
        <Route
          path="/list-settings"
          exact
          component={Auth(ListSettings, true)}
        />
        <Route path="/list-user" exact component={Auth(UserList, true)} />
        <Route
          path="/create-black-list"
          exact
          component={Auth(CreateBlackList, true)}
        />
        <Route
          path="/black-lists"
          exact
          component={Auth(BlackLists, true)}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </PageLayout>
  );
};

export default Routes;

import { backend } from "../../axios";
import { GET_ALL_SETTINGS_FAIL, GET_ALL_SETTINGS_REQUEST, GET_ALL_SETTINGS_SUCCESS } from "../../constants/settingConstants";

export const getAllSettingAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SETTINGS_REQUEST });

    const response = await backend.get('/api/settings');

    dispatch({
      type: GET_ALL_SETTINGS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_SETTINGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

import {
  BLACK_LIST_CLEAN,
  REPLACE_BLACK_LIST_SUCCESS,
  REPLACE_BLACK_LIST_ERROR,
  GET_ALL_BLACK_LIST_SUCCESS,
} from "../constants/blackLists";

export default function blackListReducer(
  state = {},
  { payload, type, error, success }
) {
  switch (type) {
    case REPLACE_BLACK_LIST_SUCCESS:
      return { ...state, isSuccess: success, isError: error };
    case REPLACE_BLACK_LIST_ERROR:
      return {
        ...state,
        alertMsg: payload,
        isError: error,
        isSuccess: success,
      };
    case GET_ALL_BLACK_LIST_SUCCESS:
      return {
        ...state,
        blackLists: payload,
        isError: error,
        isSuccess: success,
      };
    case BLACK_LIST_CLEAN:
      return {
        isError: false,
        isSuccess: false
      }
    default:
      return state;
  }
}

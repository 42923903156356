import React from 'react';
import LoginContainer from '../../containers/login_container';


const Login = () => {
    return (
        <div style={{paddingTop: "8%", minHeight: "90vh"}}>
            <LoginContainer/>
        </div>
    );
};

export default Login;
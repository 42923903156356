import React from 'react';
import AllRaces from '../../containers/race/list';
const Races = () => {
    return (
        <div>
            <AllRaces/>
        </div>
    );
};

export default Races;
export const GET_ALL_SETTINGS_REQUEST = "GET_ALL_SETTINGS_REQUEST"
export const GET_ALL_SETTINGS_SUCCESS = "GET_ALL_SETTINGS_SUCCESS"
export const GET_ALL_SETTINGS_FAIL = "GET_ALL_SETTINGS_FAIL"

export const ADD_SETTING_REQUEST = "ADD_SETTING_REQUEST"
export const ADD_SETTING_SUCCESS = "ADD_SETTING_SUCCESS"
export const ADD_SETTING_FAIL = "ADD_SETTING_FAIL"
export const ADD_SETTING_RESET = "ADD_SETTING_RESET"

export const DELETE_SETTING_REQUEST = "DELETE_SETTING_REQUEST"
export const DELETE_SETTING_SUCCESS = "DELETE_SETTING_SUCCESS"
export const DELETE_SETTING_FAIL = "DELETE_SETTING_FAIL"
export const DELETE_SETTING_RESET = "DELETE_SETTING_RESET"

export const UPDATE_SETTING_REQUEST = "UPDATE_SETTING_REQUEST"
export const UPDATE_SETTING_SUCCESS = "UPDATE_SETTING_SUCCESS"
export const UPDATE_SETTING_FAIL = "UPDATE_SETTING_FAIL"
export const UPDATE_SETTING_RESET = "UPDATE_SETTING_RESET"

export const DETAIL_SETTING_REQUEST = "DETAIL_SETTING_REQUEST"
export const DETAIL_SETTING_SUCCESS = "DETAIL_SETTING_SUCCESS"
export const DETAIL_SETTING_FAIL = "DETAIL_SETTING_FAIL"
export const DETAIL_SETTING_RESET = "DETAIL_SETTING_RESET"

import React, { useState, useEffect } from "react";
import { Input, Typography, Button, Alert } from "antd";
import "./AddSettings.css";
import { useDispatch, useSelector } from "react-redux";
import { addSettingAction } from "../../actions/setting/addSettingAction";

const { Title } = Typography;

function AddSettings() {
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const [isAlert, setIsAlert] = useState(false);
  const { addSetting, success, error } = useSelector(
    (state) => state.addSettingReducer
  );

  const addSettingHandler = () => {
    if (!name || !value) {
      setIsAlert(true);
      return;
    }

    dispatch(addSettingAction(name, value, message));
  };

  useEffect(() => {
    if (success) {
      setValue("");
      setName("");
      setMessage("");
    }
  }, [success]);

  useEffect(()=>{
    if(isAlert){
        
        setTimeout(() => {
            setIsAlert(false)
        }, 2000);
    }

  },[isAlert])

  return (
    <div className="addSettings" style={{ marginTop: "100px" }}>
      {success && (
        <Alert
          message="Successfully Created "
          style={{ width: "80%" }}
          type="success"
          showIcon
        />
      )}
      {isAlert && (
        <Alert
          message="Please Enter Name and Value Input"
          style={{ width: "80%" }}
          type="error"
          showIcon
          closable
        />
      )}

      <Title>Add Settings</Title>

      <div className="addSettings__input">
        <Input
          size="large"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ width: "50%" }}
          placeholder="Enter Setting Name"
          required
        />
        <Input
          size="large"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ width: "50%" }}
          placeholder="Enter Value"
          required
        />
        <Input
          size="large"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          style={{ width: "50%" }}
          placeholder="Enter Message"
          required
        />

        <Button type="primary" onClick={addSettingHandler}>
          Add Setting
        </Button>
      </div>
    </div>
  );
}

export default AddSettings;

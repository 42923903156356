import React, { useEffect, useState } from "react";
import { Button, Modal, Table, Input, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { Space } from "antd";

import { fetchUsers } from "../../actions/user";
import { useForm } from "antd/lib/form/Form";
import { backend } from "../../axios";
import { toast } from "react-toastify";

function GetAllBetaUsersLayout() {
  const dispatch = useDispatch();
  const [form] = useForm();
  const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState();
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const users_list = useSelector((state) => state?.user?.users_list || []);
  const currentUser = useSelector((state) => state?.user?.login)

  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            console.log("!---- ref beta node ------!", node);
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const updateHandler = (user) => {
    form.setFieldsValue({
      email: user.email,
      nickname: user.nickname,
      password: "",
      confirmPassword: "",
    });

    setUser(user);
    setIsShowUpdateModal(true);
  };

  const deleteHandler = (user) => {
    setUser(user);
    setIsShowDeleteModal(true);
  };

  const onUpdateUser = async ({ email, nickname, password }) => {
    setIsLoading(true);
    try {
      await backend.patch(`/api/users/${user.id}`, {
        nickname: nickname,
        email: email,
        password: password,
        role: 0,
      });

      toast.success("Updated user successfully");
      setIsShowUpdateModal(false);
      dispatch(fetchUsers());
    } catch (err) {
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteUser = async () => {
    setIsLoading(true);
    try {
      await backend.delete(`/api/users/${user.id}`);

      toast.success("Deleted user successfully");
      setIsShowDeleteModal(false);
      dispatch(fetchUsers());
    } catch (err) {
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "Username",
      dataIndex: "nickname",
      key: "nickname",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },

    {
      title: "Email Verify",
      dataIndex: "isVerified",
      key: "isVerified",
      render: (text, record) => {
        return record.isVerified ? "Yes" : "No";
      },
    },

    {
      title: "Created on",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => {
        return new Date(text).toDateString();
      },
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <>
            <Button
              onClick={() => updateHandler(record)}
              style={{ marginLeft: 10 }}
              type="primary"
            >
              Update
            </Button>
            <Button
              onClick={() => deleteHandler(record)}
              disabled={record.id === currentUser?.id}
              style={{ marginLeft: 10 }}
              type="danger"
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <div className="right-section">
      {<Table dataSource={users_list} columns={columns} rowKey={"id"} />}
      <Modal
        title="Update User"
        visible={isShowUpdateModal}
        okText="Update"
        onOk={() => form.submit()}
        onCancel={() => setIsShowUpdateModal(false)}
      >
        <Form
          form={form}
          name="dynamic_form_nest_item"
          onFinish={onUpdateUser}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          layout="horizontal"
          initialValues={{
            size: 100,
          }}
          size={50}
        >
          <Form.Item
            label="Email"
            name="email"
            type="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Invalid email address!",
              },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="User Name"
            name="nickname"
            type="text"
            rules={[{ required: true, message: "Please input user name!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            type="password"
            rules={[{ required: true, message: "Please input password!" }]}
            style={{ marginTop: 20 }}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            rules={[
              {
                validator(rule, value, callback, source, options) {
                  if (!value) {
                    return callback(["Please input confirm password!"]);
                  }
                  const { password } = form.getFieldsValue();

                  if (value !== password) {
                    return callback(["Password does not match!"]);
                  }

                  callback();
                },
              },
            ]}
            style={{ marginTop: 20 }}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Delete User"
        visible={isShowDeleteModal}
        okText="Delete"
        onOk={() => onDeleteUser()}
        onCancel={() => setIsShowDeleteModal(false)}
      >
        <p>
          {user
            ? `Are you sure delete ${user.nickname}?`
            : "Are you sure delete user?"}
        </p>
      </Modal>
    </div>
  );
}

export default GetAllBetaUsersLayout;

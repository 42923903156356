import React, { useEffect, useState } from "react";
import { Alert, Button, Table, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {Space } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import {
  deleteBetaUserAction,
  getAllBetaUsersAction,
} from "../../actions/beta/beta";
import {
  DELETE_BETA_USER_RESET,
} from "../../constants/betaUserConstants";


function GetAllBetaUsersLayout() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState();

  const dispatch = useDispatch();
  const [state, setState] = useState({
    searchText: '',
    searchedColumn: '',
  })

  const { allBetaUsers, loading, success, error } = useSelector(
    (state) => state.getAllBetaUsersReducer
  );
  const { success: deleteSuccess, error: deleteError } = useSelector(
    (state) => state.deleteBetaUserReducer
  );

  let searchInput;
  
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            console.log("!---- ref beta node ------!",node)
           searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() =>handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() =>handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
             setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() =>searchInput.select(), 100);
      }
    }
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
   setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = clearFilters => {
    clearFilters();
    setState({ searchText: '' });
    setFilter({});
  };

  const columns = [
    {
      title: "User Wallet ID",
      dataIndex: "id",
      key: "id",
      ...getColumnSearchProps('id'),
    },
    {
      title: "User name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    
    },
    {
      title: "Beta User At",
      dataIndex: "betaUserAt",
      key: "betaUserAt",
    
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <>
            <Button
              onClick={() => {
                dispatch(deleteBetaUserAction(record.id));
              }}
              style={{ marginLeft: 10 }}
              type="danger"
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const sort = sorter.columnKey
      ? {
          field: sorter.columnKey,
          order: sorter.order === "ascend" ? "ASC" : "DESC",
        }
      : undefined;

    setPage(pagination.current);
    setLimit(pagination.pageSize);
    setFilter(filters);
    setSort(sort);
  };

  const getAllBetaUsers = () => {
    dispatch(
      getAllBetaUsersAction({
        page,
        limit,
        filter,
        sort,
      })
    );
  };

  useEffect(() => {
    getAllBetaUsers();
  }, [page, limit, sort, filter]);

  useEffect(() => {
    getAllBetaUsers();

    if (deleteSuccess) {
      dispatch({
        type: DELETE_BETA_USER_RESET,
      });
    }

    return () => {
      dispatch({
        type: DELETE_BETA_USER_RESET,
      });
    };
  }, [deleteSuccess]);

  return (
    <div className="right-section">
      {error && <Alert message={error} type="error" showIcon />}
      {deleteSuccess && <Alert message={"User Deleted Successfully"} type="success" showIcon />}
      {allBetaUsers && (
        // <Table dataSource={allBetaUsers} columns={columns} rowKey={"id"} />
        <Table
          dataSource={allBetaUsers?.rows || []}
          onChange={handleTableChange}
          pagination={{
            pageSize: limit,
            total: allBetaUsers?.count || 0,
            current: page,
          }}
          columns={columns}
        />
      )}
    </div>
  );
}

export default GetAllBetaUsersLayout;

import {
    ADD_BETA_USERS_FAIL,
    ADD_BETA_USERS_REQUEST,
    ADD_BETA_USERS_RESET,
    ADD_BETA_USERS_SUCCESS,

    DELETE_BETA_USER_FAIL,
    DELETE_BETA_USER_REQUEST,
    DELETE_BETA_USER_RESET,
    DELETE_BETA_USER_SUCCESS,

    GET_ALL_BETA_USERS_FAIL,
    GET_ALL_BETA_USERS_REQUEST,
    GET_ALL_BETA_USERS_SUCCESS,
} from "../constants/betaUserConstants";

export const getAllBetaUsersReducer = (state = { allBetaUsers: [] }, action) => {
    switch (action.type) {
        case GET_ALL_BETA_USERS_REQUEST :
            return { loading:true, allBetaUsers : []}
           
        case GET_ALL_BETA_USERS_SUCCESS :
            return {
                loading: false,
                allBetaUsers: action.payload,
                success:true
            }
        
        case GET_ALL_BETA_USERS_FAIL :
            return { loading: false, error : action.payload }


        default:
            return state
    }
}

export const addBetaUserReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_BETA_USERS_REQUEST :
            return { loading:true}
           
        case ADD_BETA_USERS_SUCCESS :
            return {
                loading: false,
                betaUser: action.payload,
                success:true
            }
        
        case ADD_BETA_USERS_FAIL :
            return { loading: false, error : action.payload }
        
        case ADD_BETA_USERS_RESET:
            return {}

        default:
            return state
    }
}


export const deleteBetaUserReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_BETA_USER_REQUEST :
            return { loading:true}
           
        case DELETE_BETA_USER_SUCCESS :
            return {
                loading: false,
                betaUser: action.payload,
                success:true
            }
        
        case DELETE_BETA_USER_FAIL :
            return { loading: false, error : action.payload }
        
        case DELETE_BETA_USER_RESET:
            return {}

        default:
            return state
    }
}

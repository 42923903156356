
import { toast } from 'react-toastify';
import { backend } from './../axios';

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function auth() {
  const token = getCookie("auth");

  const req = backend
    .get('/api/users/auth', { token })
    .then((resp) => {
      return resp.data;
    })
    .catch(console.error());

  return {
    type: "AUTH_CHECK",
    payload: req,
  };
  // return {
  //   type: "AUTH_CHECK",
  //   payload: req
  // };
}

export async function fetchUsers() {
  try {
    const res = await backend.get('/api/users');

    return {
      type: "LIST_USERS",
      payload: res.data,
    };
  } catch (err) {
    console.log('fetchUsers', err);
  }
}

export function loginUser(username, password) {
  const req = backend
    .post('/api/users/login', { username, password })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message || err.message)
      throw err;
    });
  return {
    type: "USER_LOGIN",
    payload: req,
  };
}

export function clearLoginUser() {
  return {
    type: "USER_LOGIN_CLEAN",
    payload: {},
  };
}

export function logout() {
  const req = backend
    .post('/api/users/logout', {})
    .then((resp) => resp.data)
    .catch(console.error());

  return {
    type: "USER_lOGOUT",
    payload: req,
  };
}

export function changePassword(password) {
  const req = backend
    .patch('/api/users/change-password', { password })
    .then((resp) => {
      return resp.data;
    })
    .catch(console.error());

  return {
    type: "CHANGE_PASSWORD",
    payload: req,
  };
}

export const CREATE_RACE_REQUEST = "CREATE_RACE_REQUEST"
export const CREATE_RACE_SUCCESS = "CREATE_RACE_SUCCESS"
export const CREATE_RACE_ERROR = "CREATE_RACE_ERROR"
export const UPDATE_RACE_SUCCESS = "UPDATE_RACE_SUCCESS"
export const UPDATE_RACE_ERROR = "UPDATE_RACE_ERROR"
export const DELETE_RACE_SUCCESS = "DELETE_RACE_SUCCESS"
export const DELETE_RACE_ERROR = "DELETE_RACE_ERROR"
export const GET_ALL_RACE_SUCCESS = 'GET_ALL_RACE_SUCCESS'
export const RACE_CLEAN = 'RACE_CLEAN'

export const racePeckingOrderList = ["S", "A", "B", "C", "D", "E", "CHICK"]
export const racePeckingOrderListForPartner = ["S", "A", "B", "C", "D", "E"]
export const raceTerrainList = [
  { text: "Dirt", value: 1 },
  { text: "Grass", value: 2 },
  { text: "Road", value: 3 },
  { text: "Rock", value: 4 },
  { text: "Sand", value: 5 },
  { text: "Snow", value: 6 },
  { text: "Track", value: 7 },
]
export const raceDistanceList = [100, 120, 140, 160, 180, 200]
export const raceTypeList = [
  { text: 'Manual', value: 'manual' },
  { text: 'Automatic', value: 'automatic' },
];
export const raceGroupList = [0, 1, 2, 3, 4, 99];
export const raceStatusList = [
  { text: "Open", value: 'open' },
  { text: "Scheduled", value: 'scheduled' },
  { text: "InProgress", value: 'in_progress' },
  { text: "Finished", value: 'finished' },
  { text: "Canceled", value: 'canceled' },
]
export const racePaidStatusList = [
  { text: 'Unpaid', value: 'unpaid' },
  { text: 'ReceiptNotReady', value: 'receiptNotReady' },
  { text: 'Paid', value: 'paid' },
  { text: 'Error', value: 'error' },
  { text: 'Postponed', value: 'postponed' },
];

export const raceCoinTypeList = ['JEWEL'];

export const MAX_ENTRY_FEE_FOR_RACE = {
  WETH: 0.2,
  JEWEL: 35000, // $500
};

export const bawkStakingCompanyList = [
  {
    text: 'AVIAN RACING',
    value:  1,
  },
  {
    text: 'BLUE ROOSTER',
    value:  2,
  },
  {
    text: 'FOWL INOVATIONS',
    value:  3,
  },
  {
    text: 'BeakBets Limited',
    value:  4,
  },
  {
    text: 'GoldenNest & Co',
    value:  5,
  },
];

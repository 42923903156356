import React from 'react';
import Race from '../../containers/race/create';

const CreateRace = () => {
    return (
        <div>
            <Race/>
        </div>
    );
};

export default CreateRace;
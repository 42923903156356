import React, { useEffect, useMemo, useState } from 'react'
import { Form, Input, InputNumber, Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createRace } from '../../actions/race/race';
import { truncateToDecimals } from '../../utils';
import {
  MAX_ENTRY_FEE_FOR_RACE,
  bawkStakingCompanyList,
  raceCoinTypeList,
  raceDistanceList,
  racePeckingOrderList,
  racePeckingOrderListForPartner,
  raceTerrainList,
  raceTypeList,
} from '../../constants/raceContants';

const { Option } = Select;
const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 14,
  },
  layout: "horizontal",
  size: 100
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const CreateRace = () => {
  const [form] = Form.useForm();
  const [maxPrizePool, setMaxPrizePool] = useState()
  const [maxEntryFee] = useState(MAX_ENTRY_FEE_FOR_RACE[raceCoinTypeList[0]])
  const user = useSelector((state) => state.user.login);
  const isPartner = useMemo(() => user?.roles?.includes('partner'), [user])
  const dispatch = useDispatch();
  const peckingOrderList = useMemo(() => isPartner ? racePeckingOrderListForPartner : racePeckingOrderList, [isPartner])

  useEffect(() => {
    form.setFieldsValue({
      name: "",
      peckingOrder: "A",
      terrainId: 1,
      distance: 100,
      raceClass: "B",
      maxCapacity: 12,
      currentCapacity: 0,
      location: "",
      minimumStartDelay: 3,
      startTime: "900",
      animals: "",
      category: "",
      fee: 0,
      prizePool: 0,
      unlimitPO: 'No',
      type: 'manual',
      group: isPartner ? 101 : 1,
      allowedUserWalletIds: '',
      allowedChickenIds: '',
      coinType: raceCoinTypeList[0],
      bawkStakingCompanyId: 1,
    })
  }, [form, isPartner])

  const onChangeMaxCapacity = (value) => {
    const { fee } = form.getFieldsValue()
    form.setFieldsValue({
      maxCapacity: value
    })

    calcMaxPrizePool(fee, value)
  }

  const onChangeFee = (value) => {
    const { maxCapacity } = form.getFieldsValue()

    form.setFieldsValue({
      fee: value
    })

    calcMaxPrizePool(value, maxCapacity)
  }

  const calcMaxPrizePool = (fee, maxCapacity) => {
    if (!fee || !maxCapacity) {
      setMaxPrizePool()

      if (isPartner) {
        form.setFieldsValue({
          prizePool: 0,
        })
      }
  
      return
    }

    const prize = truncateToDecimals(fee * maxCapacity * 0.9, 6)

    if (isPartner) {
      form.setFieldsValue({
        prizePool: prize
      })
    }

    setMaxPrizePool(prize)
  }

  const onFinish = (values) => {
    dispatch(createRace({
      ...values,
      currentCapacity: 0,
    }));
  };

  return (
    <div className="right-section" autoComplete="off">
      <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item label="Enter Race Name" name="name" style={{ marginTop: 50 }} rules={[{ required: true }]}>
          <Input placeholder="Enter Race Name" />
        </Form.Item>

        <Form.Item
          label="Company"
          name="bawkStakingCompanyId"
          rules={[{ required: true }]}
        >
          <Select>
            {bawkStakingCompanyList.map((company) => (
              <Option value={company.value}>{company.text}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Race location" name="location" rules={[{ required: true }]}>
          <Input placeholder="Enter location" />
        </Form.Item>

        <Form.Item label="Pecking Order" name="peckingOrder" rules={[{ required: true }]}>
          <Select>
            {peckingOrderList.map(peckOrder => (
              <Option key={peckOrder}>{peckOrder}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Terrain" name="terrainId" rules={[{ required: true }]}>
          <Select>
            {raceTerrainList.map(terrian => (
              <Option value={terrian.value}>{terrian.text}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Distance" name="distance" rules={[{ required: true }]}>
          <Select>
            {raceDistanceList.map(distance => (
              <Option value={distance}>{distance}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Race Capacity" name="maxCapacity" rules={[{ required: true, min: isPartner ? 4 : 3, max: 12, type: 'number' }]}>
          <InputNumber
            step={1}
            style={{ width: "100%" }}
            placeholder="Enter maxCapacity"
            onChange={(value) => onChangeMaxCapacity(value)}
          />
        </Form.Item>

        {!isPartner && (
            <Form.Item label="Minimum Start Delay" name="minimumStartDelay" rules={[{ required: true, min: 0, type: 'number' }]}>
              <InputNumber style={{ width: "100%" }} placeholder="Minimum Start Delay" />
            </Form.Item>
          )
        }
        <Form.Item label="Race Start Time (seconds)" name="startTime" rules={[{ required: true }]}>
          <InputNumber style={{ width: "100%" }} placeholder="Race Start Time by seconds" />
        </Form.Item>

        <Form.Item label="Entry Fee" name="fee" rules={[{ required: true, min: 0, max: maxEntryFee, type: 'number' }]}>
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Entry Fee"
            onChange={(value) => onChangeFee(value)}
          />
        </Form.Item>

        <Form.Item
          label="Prize Pool"
          name="prizePool"
          rules={[{
            required: true,
            type: 'number',
            min: 0,
            max: maxPrizePool,
          }]}
        >
          <InputNumber disabled={isPartner} style={{ width: "100%" }} placeholder="Prize Pool" />
        </Form.Item>

        <Form.Item label="UnlimitPO" name="unlimitPO" rules={[{ required: true }]} tooltip="Ignore Pecking Orders for entering this race.">
          <Select>
            <Option value="No">No</Option>
            <Option value="Yes">Yes</Option>
          </Select>
        </Form.Item>

        {!isPartner && (
          <>

            <Form.Item label="Type" name="type" rules={[{ required: true }]}>
              <Select>
                {raceTypeList.map(type => (
                  <Option value={type.value}>{type.text}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Group" name="group" rules={[{ required: true, min: 0, max: 99, type: "number", step: 1 }]}>
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Group"
                type="number"
              />
            </Form.Item>
          </>
        )}

        <Form.Item label="Allowed User Wallets" name="allowedUserWalletIds">
          <Input placeholder="Enter allowed user wallets separated by comma" />
        </Form.Item>

        <Form.Item label="Allowed Chickens" name="allowedChickenIds">
          <Input placeholder="Enter allowed chicken ids separated by comma" />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">Add</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateRace;

import React from 'react'
import CreateBetaUserLayout from '../../containers/beta/create_beta_user'

function CreateBetaUser() {
    return (
        <div>
            <CreateBetaUserLayout/>
        </div>
    )
}

export default CreateBetaUser

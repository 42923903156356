import { combineReducers } from "redux";
import user from "./user_reducer";

import race from "./race_reducer";
import autoRacePool from "./autoRacePool";
import alert from "./alert";
import {
  addBetaUserReducer,
  deleteBetaUserReducer,
  getAllBetaUsersReducer,
} from "./betaReducer";
import {
  addSettingReducer,
  deleteSettingReducer,
  getAllSettingReducer,
  getDetailSettingReducer,
  updateSettingReducer,
} from "./settingReducer";
import blackList from './blackList';

const rootReducer = combineReducers({
  user,
  alert,
  race,
  autoRacePool,
  getAllBetaUsersReducer,
  addBetaUserReducer,
  deleteBetaUserReducer,
  addSettingReducer,
  getAllSettingReducer,
  deleteSettingReducer,
  getDetailSettingReducer,
  updateSettingReducer,
  blackList,
});
export default rootReducer;
